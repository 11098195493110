import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import axios from "axios";
import { currency } from "../../../dynamicController";
import { saveTransactionId, saveTransactionType } from "../../../redux/actions/paymentActions";
import { Select, Modal } from 'antd';

import cleanproReloadBear from "../../../assets/images/icons/icn-credit.png"

const STORETYPE = process.env.REACT_APP_STORETYPE;

const mySwal = withReactContent(Swal);

const LaundryPass = ({ user, token, theme, saveTransactionId, saveTransactionType }) => {

    const { t } = useTranslation();
    const history = useHistory();

    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const [rm, setRm] = useState(false);
    const [scan2pay, setScan2Pay] = useState(false);
    const [hitpay, setHitpay] = useState(false);
    const [pagbank, setPagbank] = useState(false);
    const [razer, setRazer] = useState(false);
    const [razerBody, setRazerBody] = useState(null);
    const [duitnow, setDuitnow] = useState(false);
    const [kiple, setKiple] = useState(false);
    const [kipleId, setKipleId] = useState(null);
    const [kipleHashValue, setKipleHashValue] = useState(null);
    const [ghl, setGhl] = useState(false);
    const [ghlBody, setGhlBody] = useState(null);
    const [thai2c2p, setThai2c2p] = useState(false);
    const [rhbDuitnow, setRhbDuitnow] = useState(false);
    const [amount, setAmount] = useState("");
    const [transId, setTransId] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [cleanproModal, setCleanproModal] = useState(false);
    const [selectedPkg, setSelectedPkg] = useState(null);
    const [state, setState] = useState(null)
    const [stateList, setStateList] = useState([])
    const [outletId, setOutletId] = useState(null)
    const [outletList, setOutletList] = useState([])
    const [outletData, setOutletData] = useState([])

    useEffect(() => {
        if (!user) {
            history.push("/");
        } else {
            setLoading(true)
            axios
                .get(`/api/subscription/fetchSubscription?operatorId=${user.store}`, { headers: { "Content-Type": "application/json", "auth-token": token } })
                .then(res => {
                    setOutletData(res.data.data.outlets)
                    setData(res.data.data.packages);
                    setKiple(res.data.data.paymentGateway.kiple);
                    setRm(res.data.data.paymentGateway.rm);
                    setScan2Pay(res.data.data.paymentGateway.scan2pay);
                    setRazer(res.data.data.paymentGateway.razer);
                    setHitpay(res.data.data.paymentGateway.hitpay);
                    setDuitnow(res.data.data.paymentGateway.duitnow);
                    setPagbank(res.data.data.paymentGateway.pagbank);
                    setGhl(res.data.data.paymentGateway.ghl);
                    setThai2c2p(res.data.data.paymentGateway.thai2c2p);
                    setRhbDuitnow(res.data.data.paymentGateway.rhbduitnow);
                    setLoading(false);
                })
                .catch(err => {
                    mySwal.fire(t("Error"), t(err.response.data.error), "error");
                    setLoading(false);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        let outletOptions = []
        let filterOutlet = outletData
        if (state) {
            filterOutlet = outletData.filter(outlet => outlet.state === state)
        }
        for (let i = 0; i < filterOutlet.length; i++) {
            let outletObject = {
                value: filterOutlet[i].id,
                label: filterOutlet[i].outlet_fullname
            }
            outletOptions.push(outletObject)
        }
        setOutletList(outletOptions)
    }, [outletData, state])

    useEffect(() => {
        let stateOptions = []

        for (let i = 0; i < outletData.length; i++) {
            if (stateOptions.filter(s => s.value === outletData[i].state).length <= 0) {
                let stateObject = {
                    value: outletData[i].state,
                    label: outletData[i].state
                }
                stateOptions.push(stateObject)
            }
        }
        setStateList(stateOptions)
    }, [outletData])

    const selectPackage = pkg => {
        setSelectedPkg(pkg)
        if (STORETYPE === "cleanpro") {
            setCleanproModal(true)
        } else {
            setShowModal(true)
        }
    }

    const cancelPurchase = () => {
        setShowModal(false);
        setCleanproModal(false);
        setSelectedPkg(null)
    }

    const purchase = (paymentGateway) => {
        setLoading(true);

        let sendThis = {
            userId: user.id,
            pkgId: selectedPkg.id,
            paymentGateway,
            outletId,
        }

        axios
            .post("/api/subscription/purchase", sendThis, { headers: { "Content-Type": "application/json", "auth-token": token } })
            .then(res => {
                setLoading(false);
                saveTransactionId(res.data.transactionId)
                saveTransactionType("Subscription")
                window.location.href = res.data.data;
            })
            .catch(err => {
                mySwal.fire(t("Error"), t(err.response.data.error), "error");
                setLoading(false);
            });
    }

    return (
        <div id="reload-page" className="laundrypass-page">
            <div id="user-header">
                <h5>
                    <i
                        onClick={(e) => history.goBack()}
                        className="bi bi-chevron-left me-3"
                    ></i>
                    <strong>{t("Laundry Pass")}</strong>
                </h5>
            </div>

            <div id="user-body" style={{ padding: "0" }}>
                {
                    loading ? (
                        <div className="text-center">
                            <div className={`spinner-border text-${STORETYPE === "cuci" ? "warning" : "primary"}`} role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                    ) : data.length <= 0 ? (
                        <div className="text-center mt-3">
                            <h3> - {t("No Promotions")} - </h3>
                        </div>
                    ) : (
                        <div>
                            {
                                data.map((x) => {
                                    return (
                                        <li
                                            className="list-group-item px-4 py-3"
                                            style={{ borderBottom: "2px solid #eeeeee" }}
                                            key={x.id}
                                        >
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="d-flex">
                                                    {
                                                        x.logo ? (
                                                            <div style={{ maxWidth: "20%" }} className="pe-2 d-flex align-items-center">
                                                                <img src={x.logo} alt="logo" />
                                                            </div>
                                                        ) : null
                                                    }
                                                    <div>
                                                        <h5 className="m-0 p-0">
                                                            {x.name}
                                                        </h5>
                                                        <p className="m-0 p-0">{t("Wash")}: {x.wash} | {t("Dry")}: {x.dry}</p>
                                                        {x.discounted_price ? <p className="m-0 p-0" style={{ color: "red" }}>{t("Save")}: {currency(user?.country)} {parseFloat(x.discounted_price).toFixed(2)}</p> : null}
                                                    </div>
                                                </div>
                                                <button
                                                    onClick={(e) => selectPackage(x)}
                                                    className="m-0 font-weight-bold btn big-button"
                                                    style={{ minWidth: "30%" }}
                                                >
                                                    {currency(user?.country)} {parseFloat(x.price).toFixed(2)}
                                                </button>
                                            </div>
                                        </li>
                                    )
                                })
                            }
                        </div>
                    )
                }
            </div>

            <Modal
                open={showModal}
                onCancel={() => setShowModal(false)}
                footer={null}
                closable={false}
                centered
            >
                <div className={theme}>
                    <h2 className="text-center">{t("Buy this package?")}</h2>
                    {selectedPkg ? <p className="text-center fs-5 mt-3 mb-3">{t("Purchase")} {selectedPkg.name} {t("for")} {currency(user?.country)} {parseFloat(selectedPkg.price).toFixed(2)}?</p> : null}
                    <p className="m-0 p-0">{t("Select your preferred outlet")}</p>
                    <div>
                        <label className="form-label" htmlFor="state" style={{ color: "black" }}>
                            {t("State")}
                        </label>
                        <Select
                            id="state"
                            name="state"
                            className="select-input"
                            allowClear
                            style={{ width: "100%" }}
                            placeholder={t("Select State")}
                            options={stateList}
                            showSearch
                            optionFilterProp="label"
                            onChange={(value) => setState(value)}
                            value={state}
                        />
                    </div>
                    <div>
                        <label className="form-label" htmlFor="outlet" style={{ color: "black" }}>
                            {t("Outlet")}
                        </label>
                        <Select
                            id="outlet"
                            name="outlet"
                            className="select-input"
                            allowClear
                            style={{ width: "100%" }}
                            placeholder={t("Select Outlet")}
                            options={outletList}
                            showSearch
                            optionFilterProp="label"
                            onChange={(value) => setOutletId(value)}
                            value={outletId}
                        />
                    </div>
                    {
                        loading ? (
                            <div className="text-center">
                                <div className={`spinner-border text-${STORETYPE === "cuci" ? "warning" : "primary"}`} role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>
                        ) : (
                            <div className="text-center">
                                {rm ? <button id="purchase-rm-token" type="button" className="btn token-reload-button btn-primary mt-2 mx-2" onClick={() => purchase("rm")}>{t("Purchase")}</button> : null}
                                {/* {kiple ? <button id="purchase-kiple-token" type="button" className="btn token-reload-button btn-primary mt-2 mx-2" onClick={() => purchase("kiple")}>{t("Pay with KiplePay")}</button> : null}
                                    {scan2pay ? <button id="purchase-scan2pay-token" type="button" className="btn token-reload-button btn-primary mt-2 mx-2" onClick={() => purchase("scan2pay")}>{t("Pay with Scan2Pay")}</button> : null}
                                    {razer ? <button id="purchase-razer-token" type="button" className="btn token-reload-button btn-primary mt-2 mx-2" onClick={() => purchase("razer")}>{t("Pay with Razer")}</button> : null}
                                    {hitpay ? <button id="purchase-hitpay-token" type="button" className="btn token-reload-button btn-primary mt-2 mx-2" onClick={() => purchase("hitpay")}>{t("Pay with HitPay")}</button> : null}
                                    {duitnow ? <button id="purchase-duitnow-token" type="button" className="btn token-reload-button btn-primary mt-2 mx-2" onClick={() => purchase("duitnow")}>{t("Pay with Duitnow QR")}</button> : null}
                                    {pagbank ? <button id="purchase-pagbank-token" type="button" className="btn token-reload-button btn-primary mt-2 mx-2" onClick={() => purchase("pagbank")}>{t("Pay with PagBank")}</button> : null}
                                    {ghl ? <button id="purchase-ghl-token" type="button" className="btn token-reload-button btn-primary mt-2 mx-2" onClick={() => purchase("ghl")}>{t("Pay with GHL")}</button> : null}
                                    {thai2c2p ? <button id="purchase-2c2p-token" type="button" className="btn token-reload-button btn-primary mt-2 mx-2" onClick={() => purchase("2c2p")}>{t("Pay with 2c2p")}</button> : null}
                                    {rhbDuitnow ? <button id="purchase-rhbduitnow-token" type="button" className="btn token-reload-button btn-primary mt-2 mx-2" onClick={() => purchase("rhbduitnow")}>{t("Pay with Duitnow QR")}</button> : null} */}
                                <button id="cancel-purchase-token" type="button" className="btn btn-danger mt-2 mx-2" onClick={() => cancelPurchase()}>{t("Cancel")}</button>
                            </div>
                        )
                    }
                </div>
            </Modal>

            <Modal
                open={cleanproModal}
                onCancel={() => setCleanproModal(false)}
                footer={null}
                closable={false}
                centered
            >
                <div className={theme}>
                    <img
                        className="d-block"
                        src={cleanproReloadBear}
                        style={{ width: "30%", margin: "0 auto" }}
                        alt=""
                    />
                    <h2 className="text-center pt-3">{t("Laundry Pass Purchase")}</h2>
                    {selectedPkg ? <p className="text-center fs-5 mt-3 mb-3">{t("Are you sure to purchase")} {selectedPkg.name} {t("for")} {currency(user?.country)} {parseFloat(selectedPkg.price).toFixed(2)}?</p> : null}
                    <p className="m-0 p-0">{t("Select your preferred outlet")}</p>
                    <div>
                        <label className="form-label" htmlFor="state" style={{ color: "black" }}>
                            {t("State")}
                        </label>
                        <Select
                            id="state"
                            name="state"
                            className="select-input"
                            allowClear
                            style={{ width: "100%" }}
                            placeholder={t("Select a state")}
                            options={stateList}
                            showSearch
                            optionFilterProp="label"
                            onChange={(value) => setState(value)}
                            value={state}
                        />
                    </div>
                    <div>
                        <label className="form-label" htmlFor="outlet" style={{ color: "black" }}>
                            {t("Outlet")}
                        </label>
                        <Select
                            id="outlet"
                            name="outlet"
                            className="select-input"
                            allowClear
                            style={{ width: "100%" }}
                            placeholder={t("Select an outlet")}
                            options={outletList}
                            showSearch
                            optionFilterProp="label"
                            onChange={(value) => setOutletId(value)}
                            value={outletId}
                        />
                    </div>
                    {
                        loading ? (
                            <div className="text-center">
                                <div className={`spinner-border text-${STORETYPE === "cuci" ? "warning" : "primary"}`} role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>
                        ) : (
                            <div className="text-center">
                                <button id="cancel-purchase-token" type="button" className="btn secondary-button mt-4 mx-2" style={{ height: "50px", width: "45%" }} onClick={() => cancelPurchase()}>{t("Cancel")}</button>
                                {rm ? <button id="purchase-rm-token" type="button" className="btn big-button mt-4 mx-2" style={{ width: "45%" }} onClick={() => purchase("rm")}>{t("Pay now")}</button> : null}
                                {/* {kiple ? <button id="purchase-kiple-token" type="button" className="btn big-button mt-3 mx-2" style={{ width: "75%" }} onClick={() => purchase("kiple")}>{t("Pay with KiplePay")}</button> : null}
                                    {scan2pay ? <button id="purchase-scan2pay-token" type="button" className="btn big-button mt-2 mx-2" style={{ width: "75%" }} onClick={() => purchase("scan2pay")}>{t("Pay with Scan2Pay")}</button> : null}
                                    {razer ? <button id="purchase-razer-token" type="button" className="btn big-button mt-2 mx-2" style={{ width: "75%" }} onClick={() => purchase("razer")}>{t("Pay with Razer")}</button> : null}
                                    {hitpay ? <button id="purchase-hitpay-token" type="button" className="btn big-button mt-2 mx-2" style={{ width: "75%" }} onClick={() => purchase("hitpay")}>{t("Pay with HitPay")}</button> : null}
                                    {duitnow ? <button id="purchase-duitnow-token" type="button" className="btn big-button mt-2 mx-2" style={{ width: "75%" }} onClick={() => purchase("duitnow")}>{t("Pay with Duitnow QR")}</button> : null}
                                    {pagbank ? <button id="purchase-pagbank-token" type="button" className="btn big-button mt-2 mx-2" style={{ width: "75%" }} onClick={() => purchase("pagbank")}>{t("Pay with PagBank")}</button> : null}
                                    {ghl ? <button id="purchase-ghl-token" type="button" className="btn big-button mt-2 mx-2" style={{ width: "75%" }} onClick={() => purchase("ghl")}>{t("Pay with GHL")}</button> : null}
                                    {thai2c2p ? <button id="purchase-2c2p-token" type="button" className="btn big-button mt-2 mx-2" style={{ width: "75%" }} onClick={() => purchase("2c2p")}>{t("Pay with 2c2p")}</button> : null}
                                    {rhbDuitnow ? <button id="purchase-rhbduitnow-token" type="button" className="btn big-button mt-2 mx-2" style={{ width: "75%" }} onClick={() => purchase("rhbduitnow")}>{t("Pay with Duitnow QR")}</button> : null} */}
                            </div>
                        )
                    }
                </div>
            </Modal>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.user.user,
        token: state.user.token,
        theme: state.theme.theme
    };
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveTransactionId: (data) => dispatch(saveTransactionId(data)),
    saveTransactionType: (data) => dispatch(saveTransactionType(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LaundryPass);