/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { useTranslation } from "react-i18next";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import { checkToken } from '../../../../redux/actions/kioskActions';
import { decrypt, logo1Controller } from '../../../../dynamicController';

import { Button } from 'react-bootstrap';
import washerImg from '../../../../assets/images/logo/PaymentWasherLogo.png';
import dryerImg from '../../../../assets/images/logo/DryerPaymentLogo.png';
import vendingImg from '../../../../assets/images/machines/vendingMachine.png';

import DryerPayment from '../payment/DryerPayment';
import WasherPayment from '../payment/WasherPayment';
import VendingPayment from '../payment/VendingPayment'

import washIcon from "../../../../assets/images/logo/WasherLogo.png";
import dryIcon from "../../../../assets/images/logo/DryerLogo.png";
import vendingIcon from "../../../../assets/images/logo/VendingLogo.png";

const mySwal = withReactContent(Swal);

const Machines = ({ machineType, checkToken, data, setPage, user, theme, userLogout, setSendThis, machine, setMachine, setEndpoint, themeLogo }) => {

    const scrollContainerRef = useRef(null);
    const { t } = useTranslation();

    const [loading, setLoading] = useState(false);
    const [outlet, setOutlet] = useState(null);
    const [showOverlay, setShowOverlay] = useState(false);
    const [duration, setDuration] = useState(0);

    useEffect(() => {
        let timeoutId;
        let lastInteractionTime = Date.now();

        const checkInactivity = () => {
            const currentTime = Date.now();
            if (currentTime - lastInteractionTime > 25000) {
                setPage('Start');
            } else {
                timeoutId = setTimeout(checkInactivity, 25000 - (currentTime - lastInteractionTime));
            }
        };

        const handleUserInteraction = () => {
            lastInteractionTime = Date.now();
        };

        timeoutId = setTimeout(checkInactivity, 25000);

        window.addEventListener('mousemove', handleUserInteraction);
        window.addEventListener('keydown', handleUserInteraction);
        window.addEventListener('touchstart', handleUserInteraction);

        return () => {
            clearTimeout(timeoutId);
            window.removeEventListener('mousemove', handleUserInteraction);
            window.removeEventListener('keydown', handleUserInteraction);
            window.removeEventListener('touchstart', handleUserInteraction);
        };
    }, [setPage]);

    useEffect(() => {
        checkToken();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const filteredMachines = data?.machineData?.filter(machineData => {
        switch (machineType) {
            case 'Washer':
                return machineData.type === 'Washer';
            case 'Dryer':
                return machineData.type === 'Dryer';
            case 'Vending Machine':
                return machineData.type === 'Vending';
            default:
                return false;
        }
    }) || [];

    const sortedMachines = [...filteredMachines].sort((a, b) => {
        const aNumber = parseInt(a.name.split('-')[1].replace(/[WDV]/g, ''));
        const bNumber = parseInt(b.name.split('-')[1].replace(/[WDV]/g, ''));

        return aNumber - bNumber;
    });

    const selectMachine = (machineNo) => {
        setLoading(true);
        setOutlet(null);
        setMachine(null);
        axios.post("/api/user/getMachine", {
            machineNo,
            outletCode: data.outletCode,
            operatorCode: data.opId,
            operatorId: data.operatorCode,
        })
            .then((res) => {
                const response = decrypt(res.data.data);

                const currentMachine = sortedMachines.find(machine => machine.machineNo === machineNo);

                if (response.outletStatus !== "online") {
                    mySwal.fire(t("Outlet is currently offline, please try again later"), "", "info");
                } else if (!response.online) {
                    mySwal.fire(t("Machine is currently offline, please try again later"), "", "info");
                } else if (response.block) {
                    mySwal.fire(t("Machine is Out of Service"), "", "info");
                } else if (currentMachine?.stack && machineType === "Dryer") {
                    mySwal.fire({
                        title: t("Please Note! (Stacked Dryer)"),
                        text: t("Ensure machine is vacant and select correct machine after payment press START"),
                        icon: "info",
                        showCancelButton: true,
                        confirmButtonText: t("Confirm"),
                        cancelButtonText: t("Cancel"),
                    }).then((result) => {
                        if (result.isConfirmed) {
                            setMachine(response);
                            setOutlet({
                                machineNo,
                                outletCode: data.outletCode,
                                operatorCode: data.opId,
                            });
                            setShowOverlay(true);
                        }
                    });
                } else {
                    setMachine(response);
                    setOutlet({
                        machineNo,
                        outletCode: data.outletCode,
                        operatorCode: data.opId
                    });
                    setShowOverlay(true);
                }
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                mySwal.fire(t("Error"), t(err.response.data.error), "error");
            });
    };

    const getImage = (type) => {
        switch (type) {
            case 'Washer':
                return washerImg;
            case 'Dryer':
                return dryerImg;
            case 'Vending Machine':
                return vendingImg;
            default:
                return null;
        }
    };

    const serviceIcon = (type) => {
        switch (type) {
            case 'Washer':
                return washIcon;
            case 'Dryer':
                return dryIcon;
            case 'Vending Machine':
                return vendingIcon;
            default:
                return null;
        }
    }

    const serviceColor = (type) => {
        switch (type) {
            case 'Washer':
                return '#004FC1';
            case 'Dryer':
                return '#f76b8a';
            case 'Vending Machine':
                return '#D3D3D3';
            default:
                return null;
        }
    }

    return (
        <div id="machines-screen" className="d-flex flex-column align-items-center secondscreen vh-100">
            <header className="d-flex flex-column w-100 p-3">
                <div className="d-flex justify-content-between align-items-start w-100">
                    <div className="d-flex flex-column align-items-start">
                        <div className="d-flex align-items-center">
                            <img
                                src={logo1Controller(themeLogo)}
                                alt="main logo"
                                className="img-fluid"
                                style={{ maxWidth: '200px', maxHeight: '200px' }}
                            />
                        </div>
                        {data && (
                            <h2 className="fs-4 fw-bold text-color mt-2 text-start w-100">
                                {data.outlet_fullname}
                            </h2>
                        )}
                    </div>
                    <div className="d-flex" style={{ marginTop: '0' }}>
                        <i
                            className="bi bi-house-fill me-3 text-color"
                            style={{ height: '50px', fontSize: '50px', cursor: 'pointer' }}
                            onClick={() => setPage('Start')}
                        ></i>
                        <i
                            className="bi bi-arrow-left-circle-fill text-primary me-3 text-color"
                            style={{ fontSize: '50px', cursor: 'pointer' }}
                            onClick={() => setPage('SelectService')}
                        ></i>
                    </div>
                </div>
            </header>

            <h2 className="text-color fw-bold mb-4">{t("Welcome")}{user ? `, ${user.name}` : ""}</h2>

            <Button
                className="btn d-flex align-items-center justify-content-center text-color rounded-pill mb-5"
                style={{
                    width: "300px",
                    height: "80px",
                    backgroundColor: serviceColor(machineType),
                    borderColor: serviceColor(machineType),
                    fontSize: "1.5rem",
                    fontWeight: "600",
                }}
            >
                {t(machineType)}
                <img src={serviceIcon(machineType)} alt={machineType} className="ms-3" style={{ height: "50px" }} />
            </Button>

            {loading ? (
                <div className="text-center mt-5">
                    <div className="spinner-border text-primary" style={{ width: "4rem", height: "4rem" }} role="status">
                        <span className="visually-hidden">{t("Loading")}</span>
                    </div>
                </div>
            ) : (
                <div className="d-flex overflow-hidden" style={{ maxHeight: "40vh", width: "100%" }}>
                    <div
                        ref={scrollContainerRef}
                        className="d-flex flex-nowrap overflow-auto"
                        style={{ scrollBehavior: "smooth", gap: "20px" }}
                    >
                        {sortedMachines.map((machineData, i) => (
                            <div
                                key={i}
                                className={`flex-shrink-0 mx-2 ${machineData.online ? "text-dark" : "text-muted"}`}
                                style={{
                                    width: "clamp(150px, 15vw, 200px)",
                                    backgroundColor: machineData.online ? serviceColor(machineType) : "#f0f0f0",
                                    borderRadius: "10px",
                                    textAlign: "center",
                                    cursor: "pointer",
                                    filter: machineData.online ? "none" : "grayscale(100%)",
                                }}
                                onClick={() => selectMachine(machineData.machineNo)}
                            >
                                <div className="position-relative">
                                    <img
                                        src={getImage(machineType)}
                                        alt={machineType}
                                        className="card-img-top p-3"
                                        style={{ height: "120px", objectFit: "contain" }}
                                    />
                                    {machineType !== "Vending Machine" && (
                                        <span
                                            className="position-absolute translate-middle fw-bold"
                                            style={{
                                                fontSize: "1.3rem", color: "black", top: '53%',
                                                left: '50%',
                                            }}
                                        >
                                            {machineData.name.split("-")[1]}
                                        </span>
                                    )}
                                </div>
                                <div className="card-body d-flex flex-column justify-content-center align-items-center">
                                    <p className="fw-bold mb-3 text-color" style={{ fontSize: "1.1rem" }}>
                                        {t(machineData.type)} {machineData.capacity}
                                    </p>
                                    <button
                                        className={`btn ${machineData.running ? "btn-danger" : "btn-success"} w-75 px-2 py-1 text-nowrap mb-3`}
                                        style={{
                                            fontSize: "1rem",
                                            fontWeight: "600",
                                            borderRadius: "10px",
                                        }}
                                    >
                                        {machineData.running ? t("Running") : t("Vacant")}
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}

            {showOverlay && machine && machineType === "Washer" ? (
                <WasherPayment
                    machine={machine}
                    outlet={outlet}
                    user={user}
                    theme={theme}
                    setDuration={setDuration}
                    setPage={setPage}
                    userLogout={userLogout}
                    setSendThis={setSendThis}
                    setShowOverlay={setShowOverlay}
                    setEndpoint={setEndpoint}
                    kioskVersion={2}
                />
            ) : showOverlay && machine && machineType === "Dryer" ? (
                <DryerPayment
                    machine={machine}
                    outlet={outlet}
                    user={user}
                    theme={theme}
                    setDuration={setDuration}
                    setPage={setPage}
                    userLogout={userLogout}
                    setSendThis={setSendThis}
                    setShowOverlay={setShowOverlay}
                    setEndpoint={setEndpoint}
                    kioskVersion={2}
                />
            ) : showOverlay && machine && machineType === "Vending Machine" ? (
                <VendingPayment
                    machine={machine}
                    outlet={outlet}
                    user={user}
                    theme={theme}
                    setPage={setPage}
                    userLogout={userLogout}
                    setSendThis={setSendThis}
                    setShowOverlay={setShowOverlay}
                    setEndpoint={setEndpoint}
                    kioskVersion={2}
                />
            ) : null}
        </div>
    );

};

const mapStateToProps = state => {
    return {
        data: state.kiosk.data,
        theme: state.theme.theme,
        themeLogo: state.theme.logo
    };
};

const mapDispatchToProps = dispatch => {
    return {
        checkToken: () => dispatch(checkToken())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Machines);
